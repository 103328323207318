import React from 'react'
import { DropdownButton, Dropdown } from 'react-bootstrap'


const selection = {
  "0": "∟",
  "3.2": "S╰",
  "4.8": "M╰",
  "6.4": "L╰",
  "9.5": "XL╰",
}

function RadiusSelector(props) {
  const { cornerRadius, changeRadius } = props

  return (
    <DropdownButton id="dropdown-item-button" variant="outline" title={selection[cornerRadius]} style={{float: 'left', marginLeft: '2%'}}>
      <Dropdown.Item as="button" active={cornerRadius === 0} onSelect={(e) => {changeRadius(e, 0)}} >0mm | 0" | No round</Dropdown.Item>
      <Dropdown.Item as="button" active={cornerRadius === 3.2} onSelect={(e) => {changeRadius(e, 3.2)}} >3.2mm | 1/8" | S round</Dropdown.Item>
      <Dropdown.Item as="button" active={cornerRadius === 4.8} onSelect={(e) => {changeRadius(e, 4.8)}} >4.8mm | 3/16" | M round</Dropdown.Item>
      <Dropdown.Item as="button" active={cornerRadius === 6.4} onSelect={(e) => {changeRadius(e, 6.4)}} >6.4mm | 1/4" | L round</Dropdown.Item>
      <Dropdown.Item as="button" active={cornerRadius === 9.5} onSelect={(e) => {changeRadius(e, 9.5)}} >9.5mm | 3/8" | XL round</Dropdown.Item>
    </DropdownButton>
  )
}

export default RadiusSelector