import React from 'react'
import '@google/model-viewer'

function ModelViewer(props) {
  const { fileName, localFile } = props

  var src, iosSrc

  if (localFile) {
    src = fileName
    iosSrc = null
  } else {
    src = (fileName ? fileName + ".glb" : '/static/placeholders/origamiscan.glb')
    iosSrc = (fileName ? fileName + ".usdz" : '/static/placeholders/origamiscan.usdz')
  }
  
  return (
    <model-viewer 
      src={src}
      ios-src={iosSrc}
      alt="3D Model"
      shadow-intensity="0"
      stage-light-intensity="2" 
      environment-intensity="2"
      environment-image="/static/white.png"
      camera-orbit="0deg 75deg 95%"
      exposure="1"
      camera-controls
      ar magic-leap
      quick-look-browsers="safari chrome"
      style={{width: '100%', height: '100%', border: 'none !important' }}
    />
  )
}

export default ModelViewer