import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { SketchPicker } from 'react-color'


function EdgeIcon(color) { return(
 <svg className="bi bi-image" width="1.5em" height="1.5em" viewBox="0 0 20 20" fill={color} xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" d="M16.002 4h-12a1 1 0 00-1 1v10a1 1 0 001 1h12a1 1 0 001-1V5a1 1 0 00-1-1zm-12-1a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2h-12z" clipRule="evenodd"/>
  </svg>
)}

const ImagesIcon = (
  <svg className="bi bi-images" width="1.5em" height="1.5em" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" d="M14.002 6h-10a1 1 0 00-1 1v8a1 1 0 001 1h10a1 1 0 001-1V7a1 1 0 00-1-1zm-10-1a2 2 0 00-2 2v8a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-10z" clipRule="evenodd"/>
    <path d="M12.648 10.646a.5.5 0 01.577-.093l1.777 1.947V16h-12v-1l2.646-2.354a.5.5 0 01.63-.062l2.66 1.773 3.71-3.71z"/>
    <path fillRule="evenodd" d="M6.502 11a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM6 4h10a1 1 0 011 1v8a1 1 0 01-1 1v1a2 2 0 002-2V5a2 2 0 00-2-2H6a2 2 0 00-2 2h1a1 1 0 011-1z" clipRule="evenodd"/>
  </svg>
)

const BackImagesIcon = (
  <svg className="bi bi-images" width="1.5em" height="1.5em" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" d="M14.002 6h-10a1 1 0 00-1 1v8a1 1 0 001 1h10a1 1 0 001-1V7a1 1 0 00-1-1zm-10-1a2 2 0 00-2 2v8a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-10z" clipRule="evenodd"/>
    <path fillRule="evenodd" d="M6 4h10a1 1 0 011 1v8a1 1 0 01-1 1v1a2 2 0 002-2V5a2 2 0 00-2-2H6a2 2 0 00-2 2h1a1 1 0 011-1z" clipRule="evenodd"/>
  </svg>
)

function selection(title) {
  switch (title) {
    case "front": 
      return(ImagesIcon)
    case "back":
      return(BackImagesIcon)
    default:
      return (EdgeIcon(title))
  }
}



function EdgeSelector(props) {
  const { edge, changeEdge } = props

  var customColor = edge.split("#")[0] === "" ? edge : '#ffffff'

  return (
    <Dropdown style={{marginLeft: '2%', float: 'left'}}>
      <Dropdown.Toggle id="dropdown-edge-selector" variant="outline">{selection(edge)}</Dropdown.Toggle>
      <Dropdown.Menu className="edge-menu">
        <Dropdown.Item as="button" active={edge === "front"} onSelect={(e) => {changeEdge(e, "front")}} >
          Edge Front Wrap 
          <span style={{float: 'right'}}>{ImagesIcon}</span>
        </Dropdown.Item>
        <Dropdown.Item as="button" active={edge === "back"} onSelect={(e) => {changeEdge(e, "back")}} >
          Edge Back Wrap 
          <span style={{float: 'right'}}>{BackImagesIcon}</span>
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item 
          as="button" 
          active={edge.split("#")[0] === ""} 
          onSelect={(e) => {changeEdge(e, customColor) }}
          style={{position: 'relative'}} 
        >Edge Color
          <div style={{ background: customColor, right: '10px', top: '10%', position: 'absolute', width: '20%', height: '80%', border: '1px solid gray', borderRadius: '2px' }}/>
        </Dropdown.Item>
        <SketchPicker
          disableAlpha={true}
          color={ customColor }
          onChangeComplete={ (e) => {changeEdge(e, e.hex)} }
          presetColors={['#FFFFFF', '#000000', '#D0021B', '#F5A623', '#F8E71C', '#8B572A', '#BD10E0', '#666666' ]}
        />
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default EdgeSelector
