import React from 'react'
import { DropdownButton, Dropdown } from 'react-bootstrap'


const paper = (
  <svg className="bi bi-document-richtext" width="1.5em" height="1.5em" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" d="M6 3h8a2 2 0 012 2v10a2 2 0 01-2 2H6a2 2 0 01-2-2V5a2 2 0 012-2zm0 1a1 1 0 00-1 1v10a1 1 0 001 1h8a1 1 0 001-1V5a1 1 0 00-1-1H6z" clipRule="evenodd"/>
    <path fillRule="evenodd" d="M6.5 14a.5.5 0 01.5-.5h3a.5.5 0 010 1H7a.5.5 0 01-.5-.5zm0-2a.5.5 0 01.5-.5h6a.5.5 0 010 1H7a.5.5 0 01-.5-.5zm1.639-3.958l1.33.886 1.854-1.855a.25.25 0 01.289-.047L13.5 8v1.75a.5.5 0 01-.5.5H7a.5.5 0 01-.5-.5v-.5s1.54-1.274 1.639-1.208zM8.25 7a.75.75 0 100-1.5.75.75 0 000 1.5z" clipRule="evenodd"/>
  </svg>
)

const postcard = (
  <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 480 480">
    <g>
      <path d="M0,80v320h480V80H0z M460,380H20V100h440V380z"/>
      <rect x="230" y="130" width="20" height="220"/>
      <path d="M440.763,120H333.752v120h107.011V120z M420.763,220h-67.011v-80h67.011V220z"/>
      <rect x="311.129" y="280" width="119.634" height="20"/>
      <rect x="311.129" y="320" width="119.634" height="20"/>
      <rect x="45.132" y="160" width="135.508" height="20"/>
      <rect x="45.132" y="210" width="135.508" height="20"/>
      <rect x="45.132" y="260" width="77.792" height="20"/>
    </g>
  </svg>
)

const photo = (
  <svg className="bi bi-image-fill" width="1.5em" height="1.5em" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" d="M2.002 5a2 2 0 012-2h12a2 2 0 012 2v10a2 2 0 01-2 2h-12a2 2 0 01-2-2V5zm1 9l2.646-2.354a.5.5 0 01.63-.062l2.66 1.773 3.71-3.71a.5.5 0 01.577-.094l3.777 1.947V15a1 1 0 01-1 1h-12a1 1 0 01-1-1v-1zm5-6.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" clipRule="evenodd"/>
  </svg>
)

const envelope = (
  <svg className="bi bi-envelope" width="1.5em" height="1.5em" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" d="M16 5H4a1 1 0 00-1 1v8a1 1 0 001 1h12a1 1 0 001-1V6a1 1 0 00-1-1zM4 4a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2H4z" clipRule="evenodd"/>
    <path fillRule="evenodd" d="M2.071 6.243a.5.5 0 01.686-.172L10 10.417l7.243-4.346a.5.5 0 11.514.858L10 11.583 2.243 6.93a.5.5 0 01-.172-.686z" clipRule="evenodd"/>
  </svg>
)

const thickEnvelope = (
    <svg className="bi bi-envelope" width="1.5em" height="1.5em" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path stroke="currentColor" strokeWidth="0.8" fillRule="evenodd" d="M16 5H4a1 1 0 00-1 1v8a1 1 0 001 1h12a1 1 0 001-1V6a1 1 0 00-1-1zM4 4a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2H4z" clipRule="evenodd"/>
      <path fillRule="evenodd" d="M2.071 6.243a.5.5 0 01.686-.172L10 10.417l7.243-4.346a.5.5 0 11.514.858L10 11.583 2.243 6.93a.5.5 0 01-.172-.686z" clipRule="evenodd"/>
    </svg>
)

const card = (
  <svg className="bi bi-credit-card" width="1.5em" height="1.5em" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" d="M16 5H4a1 1 0 00-1 1v8a1 1 0 001 1h12a1 1 0 001-1V6a1 1 0 00-1-1zM4 4a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2H4z" clipRule="evenodd"/>
    <rect width="3" height="3" x="4" y="11" rx="1"/>
    <path d="M3 7h14v2H3z"/>
  </svg>
)


const selection = {
  "0.1": paper,
  "0.25": photo,
  "0.5": postcard,
  "0.75": card,
  "1.2": envelope,
  "2.4": thickEnvelope
}

function ThicknessSelector(props) {
  const { thickness, changeThickness } = props

  return (
    <DropdownButton id="dropdown-item-button" variant="outline" title={selection[thickness]} style={{float: 'left'}}>
      <Dropdown.Item as="button" active={thickness === 0.1} onSelect={(e) => {changeThickness(e, 0.1)}} >0.10mm | 0.004" | Paper</Dropdown.Item>
      <Dropdown.Item as="button" active={thickness === 0.25} onSelect={(e) => {changeThickness(e, 0.25)}} >0.25mm | 0.01" | Photo</Dropdown.Item>
      <Dropdown.Item as="button" active={thickness === 0.5} onSelect={(e) => {changeThickness(e, 0.5)}} >0.50mm | 0.02" | Biz/Postcard</Dropdown.Item>
      <Dropdown.Item as="button" active={thickness === 0.75} onSelect={(e) => {changeThickness(e, 0.75)}} >0.75mm | 0.03" | ID/Credit Card</Dropdown.Item>
      <Dropdown.Item as="button" active={thickness === 1.2} onSelect={(e) => {changeThickness(e, 1.2)}} >1.20mm | 0.05" | Thin Envelope</Dropdown.Item>
      <Dropdown.Item as="button" active={thickness === 2.4} onSelect={(e) => {changeThickness(e, 2.4)}} >2.40mm | 0.10" | Thick Envelope</Dropdown.Item>
    </DropdownButton>
  )
}

export default ThicknessSelector