import React, { Component } from 'react'
import axios from 'axios'
import ImageViewer from './image-viewer'
import ModelFooter from './model-footer'
import ModelViewer from './model-viewer'
//import DownloadHeader from './download-header'
import DownloadSelector from './download-selector'
import ThicknessSelector from './thickness-selector'
import RadiusSelector from './radius-selector'
import EdgeSelector from './edge-selector'
import Dropzone from 'react-dropzone'
import { Spinner } from 'react-bootstrap'


export default class ModelMaker extends Component {
  fileObj = [];
  maxFiles = 2;

  constructor(props) {
    super(props)
    this.state = {
      file: [],
      rotate: [0, 0],
      maxDim: 0,
      aspectRatio: [],
      swapped: false,
      model: null,
      thickness: 0.25,
      cornerRadius: 0,
      dpi: 600,
      edge: "back",
      localFile: false,
      loading: false,
    }
    this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this)
    this.uploadFiles = this.uploadFiles.bind(this)
    this.getMaxDimension = this.getMaxDimension.bind(this)
    this.rotateImages = this.rotateImages.bind(this)
    this.rotateFront = this.rotateFront.bind(this)
    this.rotateBack = this.rotateBack.bind(this)
    this.swapImages = this.swapImages.bind(this)
    this.createUSDZ = this.createUSDZ.bind(this)
    this.downloadGLB = this.downloadGLB.bind(this)
    this.downloadUSDZ = this.downloadUSDZ.bind(this)
    this.changeThickness = this.changeThickness.bind(this)
    this.changeRadius = this.changeRadius.bind(this)
    this.changeEdge = this.changeEdge.bind(this)
    this.openFile = this.openFile.bind(this)
    this.load3D = this.load3D.bind(this)
  }


  openFile(e) {
    // Open button handler
    // Check if canceled event
    if (e.target.files.length === 0) {
      return
    }

    this.uploadMultipleFiles(e.target.files)
  }


  uploadMultipleFiles(files) {
    const { file, aspectRatio } = this.state
    var fileArray = file

    // Handle 1x Files
    if (aspectRatio.length === 1) {
      if (this.fileObj.length === 1) {
        // add single photo to replace back placeholder
        this.fileObj.push(files[0])
        let url = URL.createObjectURL(files[0])
        if (this.state.swapped) {
          fileArray[0] = (url)     // overwrite placeholder  
        } else {
          fileArray[1] = (url)     // overwrite placeholder  
        }
        this.getMaxDimension(url)
        this.setState({file: fileArray})
      } else {
        // reset back to 1 file and placeholder for 0 or 2 
        fileArray = []
        this.fileObj = []
        this.fileObj.push(files[0])
        let url = URL.createObjectURL(files[0])
        fileArray.push(url)
        this.getMaxDimension(url)

        this.setState({
          file: fileArray,
          aspectRatio: [],
          model: null,
        })

      }

      return
    }

    // Handle 2x Files at once
    this.fileObj = []
    fileArray = []

    for (let i = 0; i < files.length; i++) {
      if (i > 1) {
        break
      }
      this.fileObj.push(files[i])
      var url = URL.createObjectURL(files[i])
      fileArray.push(url)
      this.getMaxDimension(url)
    }
    this.setState({ 
      file: fileArray,
      rotate: [0, 0],
      aspectRatio: [],
      swapped: false,
      model: null,
    })
  }

  getMaxDimension(URL) {
    const maxDim = this.state.maxDim
    var _self = this
    var img = new Image()

    img.onload = function() {
      var max = Math.max(this.width, this.height)
      if (max > maxDim) {
        _self.setState({maxDim: max})
      }

      // Dimensions load in reverse order from DOM, need to check
      var aspectRatio = _self.state.aspectRatio
      var ar = this.width / this.height

      if (aspectRatio.length !== 0 && URL === _self.state.file[0]) {
        aspectRatio.unshift(ar)
      } else {
        aspectRatio.push(ar)  
      }

      _self.setState({aspectRatio: aspectRatio})  // landscape > 1, portrait < 1
    }

    img.src = URL
  }

  uploadFiles(e) {
    e.preventDefault()
    console.log(this.state.file)
  }

  rotateImages(e) {
    const { rotate } = this.state
    this.setState({rotate: [rotate[0] + 0.25, rotate[1] - 0.25]})
  }

  rotateFront(e) {
    const { rotate } = this.state
    this.setState({rotate: [rotate[0] + 0.25, rotate[1]]})
  }

  rotateBack(e) {
    const { rotate } = this.state
    this.setState({rotate: [rotate[0], rotate[1] - 0.25]})
  }

  swapImages(e) {
    const { swapped, rotate, aspectRatio, file } = this.state
    const reversed = file.reverse()

    // Check if only has 1 photo
    if (aspectRatio.length === 1) {
      if (swapped) {
        reversed[1] = reversed[1].replace("front", "back")
      } else {
        reversed[0] = reversed[0].replace("back", "front")
      }
    }

    this.setState({ 
      rotate: [rotate[1], rotate[0]],
      file: reversed,
      swapped: !swapped,
      aspectRatio: aspectRatio.reverse(),
    })

  }

  createUSDZ(e) {
    const data = new FormData()
    const { rotate, swapped, thickness, cornerRadius, edge } = this.state

    const path = process.env.NODE_ENV === 'development' ? "http://" + window.location.hostname + ':5000/' : ''

    // Add files to the dataload (need to do error checking)
    if (swapped) {
      data.append('file', this.fileObj[1])
      data.append('file', this.fileObj[0])
    } else {
      data.append('file', this.fileObj[0])
      data.append('file', this.fileObj[1])
    }
    
    // Add rotation data in degrees, direction change (need to do error checking)
    data.append('rotate', rotate[0] * 360)
    data.append('rotate', rotate[1] * 360)    // for backside follow
    data.append('thickness', thickness)
    data.append('radius', cornerRadius)
    data.append('edge', edge)

    this.setState({loading: true})

    axios.post("/api/upload", data, {   
    })
    .then(res => {
      console.log(res.data)
      this.setState({ 
        model: path + res.data.filePath,
        localFile: false,
        loading: false,
      })
    })
  }

  downloadGLB(e) {
    const link = this.state.model ? this.state.model + ".glb" : "/static/placeholders/origamiscan.glb"

    console.log(link)
    this.downloadURI(link, "origamiscan.glb")
  }

  downloadUSDZ(e) {
    const link = this.state.model ? this.state.model + ".usdz" : "/static/placeholders/origamiscan.usdz"

    this.downloadURI(link, "origamiscan.usdz")
  }

  downloadURI(uri, name) {
    var link = document.createElement("a");
    link.download = name;
    link.href = uri;
    link.click();
  }

  changeThickness(e, thickness) {
    this.setState({ thickness: thickness })
  }

  changeRadius(e, radius) {
    this.setState({ cornerRadius: radius })
  }

  changeEdge(e, edge) {
    if (edge === null) {
      return
    }

    this.setState({ edge: edge })
  }

  load3D(file) {
    this.setState({
      model: URL.createObjectURL(file[0]),
      localFile: true 
    })
  }


  render() {
    const { file, rotate, aspectRatio, model, thickness, cornerRadius, dpi, maxDim, edge, localFile, loading } = this.state

    const overlay = (
      <div id="loading">
        <Spinner animation="border" role="status" variant="primary" >
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    )

  	return(
      <div className="card">
        <div className="card-header">
          <DownloadSelector 
            file={file}
            model={model}
            downloadUSDZ = {this.downloadUSDZ}
            downloadGLB = {this.downloadGLB}
          />
          <ThicknessSelector 
            thickness={thickness}
            changeThickness={this.changeThickness}
          />
          <RadiusSelector
            cornerRadius={cornerRadius}
            changeRadius={this.changeRadius}
          />
          <EdgeSelector
            edge={edge}
            changeEdge={this.changeEdge}
          />
        </div>
        <div className="card-body unselectable" style={{height: '60%', padding: '0', position: 'relative'}}>
          {loading ? overlay : null}
          <Dropzone onDrop={acceptedFile => this.load3D(acceptedFile)} >
            {({getRootProps}) => (
              <div style={{width: '100%', height: '100%'}} {...getRootProps()}>
                <ModelViewer 
                  fileName = {model}
                  localFile = {localFile}
                />
              </div>
            )}
          </Dropzone>
        </div>
        <Dropzone onDrop={acceptedFiles => this.uploadMultipleFiles(acceptedFiles)} >
          {({getRootProps}) => (
            <div className="card-body unselectable image-viewer" {...getRootProps()}>
              <ImageViewer 
                loaded = {file.length !== 0}
                aspectRatio = {aspectRatio}
                rotate = {rotate}
                file = {file}
                rotateFront = {this.rotateFront}
                rotateBack = {this.rotateBack}
                cornerRadius = {cornerRadius}
                dpi = {dpi}
                maxDim = {maxDim}
              />
            </div>
          )}
        </Dropzone>
        <div className="card-footer" id="model-footer">
          <ModelFooter
            loaded = {file.length !== 0}
            openFile = {this.openFile}
            rotateImages = {this.rotateImages}
            swapImages = {this.swapImages}
            createUSDZ = {this.createUSDZ}
          />
        </div>
      </div>
    )
  }
}
