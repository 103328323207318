import React from 'react'
import { Button } from 'react-bootstrap'

const rotateIcon = (
  <React.Fragment>
    <svg className="bi bi-arrow-clockwise" width="1.2em" height="1.2em" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" d="M10 4.5a5.5 5.5 0 105.5 5.5.5.5 0 011 0 6.5 6.5 0 11-3.25-5.63l-.5.865A5.472 5.472 0 0010 4.5z" clipRule="evenodd"/>
      <path fillRule="evenodd" d="M10.646 1.646a.5.5 0 01.708 0l2.5 2.5a.5.5 0 010 .708l-2.5 2.5a.5.5 0 01-.708-.708L12.793 4.5l-2.147-2.146a.5.5 0 010-.708z" clipRule="evenodd"/>
    </svg>
    <svg className="bi bi-arrow-counterclockwise" width="1.2em" height="1.2em" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" d="M10 4.5A5.5 5.5 0 114.5 10a.5.5 0 00-1 0 6.5 6.5 0 103.25-5.63l.5.865A5.472 5.472 0 0110 4.5z" clipRule="evenodd"/>
      <path fillRule="evenodd" d="M9.354 1.646a.5.5 0 00-.708 0l-2.5 2.5a.5.5 0 000 .708l2.5 2.5a.5.5 0 10.708-.708L7.207 4.5l2.147-2.146a.5.5 0 000-.708z" clipRule="evenodd"/>
    </svg>
  </React.Fragment>
)

const openIcon = (
  <svg className="bi bi-plus" style={{height: '100%'}} width="1.5em" height="1.5em" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" d="M10 5.5a.5.5 0 01.5.5v4a.5.5 0 01-.5.5H6a.5.5 0 010-1h3.5V6a.5.5 0 01.5-.5z" clipRule="evenodd"/>
    <path fillRule="evenodd" d="M9.5 10a.5.5 0 01.5-.5h4a.5.5 0 010 1h-3.5V14a.5.5 0 01-1 0v-4z" clipRule="evenodd"/>
  </svg>
)

const swapIcon = (
  <svg className="bi bi-arrow-left-right" width="1.5em" height="1.5em" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" d="M12.146 9.646a.5.5 0 01.708 0l3 3a.5.5 0 010 .708l-3 3a.5.5 0 01-.708-.708L14.793 13l-2.647-2.646a.5.5 0 010-.708z" clipRule="evenodd"/>
    <path fillRule="evenodd" d="M4 13a.5.5 0 01.5-.5H15a.5.5 0 010 1H4.5A.5.5 0 014 13zm3.854-9.354a.5.5 0 010 .708L5.207 7l2.647 2.646a.5.5 0 01-.708.708l-3-3a.5.5 0 010-.708l3-3a.5.5 0 01.708 0z" clipRule="evenodd"/>
    <path fillRule="evenodd" d="M4.5 7a.5.5 0 01.5-.5h10.5a.5.5 0 010 1H5a.5.5 0 01-.5-.5z" clipRule="evenodd"/>
  </svg>
)

const origamiIcon = (
  <img alt="Origami Icon" src="logo192.png" style={{height: '2.0rem', marginBottom: '0rem'}}/>
)

function ModelFooter(props) {
  const { openFile, rotateImages, swapImages, createUSDZ, loaded } = props

  return (
    <div className="btn-group btn-block" style={{height: '100%'}}>
      <Button className={!loaded ? "blinking" : ""} title="Add Images" variant="outline-secondary" style={{width: '25%', height: '100%', padding:'0'}} id="add-images" >
        <label 
          htmlFor="fileUpload" 
          className="file-upload" 
          style={{cursor: 'pointer', width: '100%', height: '100%', display: 'flex', justifyContent: 'center',  alignItems: 'center', minHeight: '50px' }}
        >{openIcon}
          <input id="fileUpload" type="file" onChange={openFile} multiple/>
        </label>
      </Button>
      <Button title="Rotate Images" variant="outline-secondary" onClick={rotateImages} disabled={!loaded} style={{width: '25%', whiteSpace: 'nowrap' }} >{rotateIcon}</Button>
      <Button title="Swap Images" variant="outline-secondary" onClick={swapImages} disabled={!loaded} style={{width: '25%'}} >{swapIcon}</Button>
      <Button className={loaded ? "blinking" : ""} title="Make 3D" variant="outline-secondary" onClick={createUSDZ} disabled={!loaded} style={{width: '25%'}} >{origamiIcon}</Button>
    </div>
  )
}

export default ModelFooter