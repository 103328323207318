import React from 'react'

const frontIcon = (
  <svg className="bi bi-arrow-clockwise" width="50%" height="50%" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" d="M10 4.5a5.5 5.5 0 105.5 5.5.5.5 0 011 0 6.5 6.5 0 11-3.25-5.63l-.5.865A5.472 5.472 0 0010 4.5z" clipRule="evenodd"></path>
    <path fillRule="evenodd" d="M10.646 1.646a.5.5 0 01.708 0l2.5 2.5a.5.5 0 010 .708l-2.5 2.5a.5.5 0 01-.708-.708L12.793 4.5l-2.147-2.146a.5.5 0 010-.708z" clipRule="evenodd"></path>
  </svg>
)

const backIcon = (
  <svg className="bi bi-arrow-counterclockwise" width="50%" height="50%" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" d="M10 4.5A5.5 5.5 0 114.5 10a.5.5 0 00-1 0 6.5 6.5 0 103.25-5.63l.5.865A5.472 5.472 0 0110 4.5z" clipRule="evenodd"></path>
    <path fillRule="evenodd" d="M9.354 1.646a.5.5 0 00-.708 0l-2.5 2.5a.5.5 0 000 .708l2.5 2.5a.5.5 0 10.708-.708L7.207 4.5l2.147-2.146a.5.5 0 000-.708z" clipRule="evenodd"></path>
  </svg>  
)

function ImageViewer(props) {
  const { aspectRatio, rotate, file, rotateFront, rotateBack, loaded, cornerRadius, dpi, maxDim } = props

  var fileArray = file

  if (!loaded) {
    fileArray = ["../static/placeholders/front.jpg", "../static/placeholders/back.jpg"]
  } else if (fileArray.length === 1) {
    fileArray.push("../static/placeholders/back.jpg")
  }

  return (
    <div className="form-group multi-preview" style={{ display: 'flex', height: '100%' }} >
      
      {(fileArray || []).map((url, index) => {
        var scale = 100
        var height = 100
        var borderRadius = ''

        // Scale adjustment for height
        if(aspectRatio.length === 0) {
          scale = scale * (2 / 3)    // Placeholder image
        } else {
          if (aspectRatio[index] > 1) {       // landscape
            scale = scale / aspectRatio[index]
            height = scale
          } else {
            scale = scale * aspectRatio[index]
          }
        }

        if (cornerRadius > 0) {
          var cornerPercent = 100 * (cornerRadius / 25.4) / ((maxDim ? maxDim : 3600) / dpi) 

          if (aspectRatio.length !== 0) {
            if (aspectRatio[index] > 1) { 
              borderRadius = cornerPercent + "% / " + (cornerPercent * aspectRatio[index]) + "%"  
            } else {
              borderRadius = (cornerPercent / aspectRatio[index]) + "% / " + cornerPercent + "%"  
            }
          } else {
            borderRadius = (cornerPercent / (2/3)) + "% / " + cornerPercent + "%"
          }
        }
      
        return(
          <div 
            style={{
              width: '48%',
              margin: '0 auto',
              position: 'relative',
            }}
            key={url ? url.toString() : null} 
            >
              <img 
                src={url} 
                alt="..." 
                style={{
                  transform: 'rotate(' + rotate[index] + 'turn) translate(-50%, -50%)', 
                  transition: 'transform 0.5s',
                  zIndex: 100 - index,
                  height: height + '%',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  borderRadius: borderRadius,
                  transformOrigin: 'top left',
                }}
              />
            { loaded ? (
                <button
                  type="button" 
                  className="btn btn-rotate" 
                  onClick={ index === 0 ? rotateFront : rotateBack}
                >
                  { index === 0 ? frontIcon : backIcon }
                </button>

            ) : null }
          </div>
        )
      })}
    </div>
  )
}

export default ImageViewer