import React from 'react'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import ModelMaker from './components/model-maker'

function App() {
  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <ModelMaker />
        </div>
      </div>
    </div>
  );
}

export default App;